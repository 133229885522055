import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"

const NotFoundPage = () =>
  <Layout>
    <div className="NotFound">
      <Helmet
        title="Page Not Found - Conner Fritz"
      />
      <h2>NOTHING TO SEE HERE</h2>
      <p>The page you were looking for doesn't seem to be here. Sorry about that.</p>
    </div>
  </Layout>
export default NotFoundPage
